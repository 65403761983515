// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Mon Jul 22 2024 13:12:23 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: "kwongtn",
        branch: "main",
        hash: "0e73fa",
        fullHash: "0e73fa0697718a1b15e4235096eb91ee48754047"
    }
};

export default build;